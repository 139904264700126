import { ReactNode } from "react";
import { MobileNav, MobileNavProps } from "../spine";
import * as css from "./style.css";

export function MobileNavLayout(
  props: MobileNavProps & { children: ReactNode },
) {
  const { children, ...forward } = props;

  return (
    <>
      <MobileNav {...forward} />
      <div className={css.content}>{children}</div>
    </>
  );
}
