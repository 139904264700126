import { Image } from "@/ui/image";
import { Route, Switch } from "wouter";
import { ErrorMessage } from "../error-message";
import { useFetcher } from "../hooks/use-fetcher";
import { Link } from "../link";
import { LoadingIndicator } from "../loading-indicator";
import { MobileNavLayout } from "../mobile-nav-layout";
import * as css from "./style.css";

export function CoverImage(props: { src: string }) {
  return (
    <Image
      className={css.coverImage}
      src={props.src}
      width={1875}
      height={912}
      alt=""
    />
  );
}

export function ListingItem(props: {
  href: string;
  name: string;
  createdAt?: Date | null;
  coverImageSrc?: string;
  showCover?: boolean;
}) {
  const date = (props.createdAt ?? new Date("2023-06-25")).toLocaleDateString(
    "en-GB",
    {
      dateStyle: "long",
    },
  );

  return (
    <Link href={props.href} className={css.changelogItem}>
      {props.showCover && props.coverImageSrc && (
        <CoverImage src={props.coverImageSrc} />
      )}
      <h2 className={css.changelogItemHeading}>{props.name}</h2>
      <div className={css.changelogItemDate}>{date}</div>
    </Link>
  );
}

export function createDocsPages<
  T extends { id: string; href: string },
>(config: {
  /**
   * Mobile and sidebar navigation title.
   */
  title: string;

  /**
   * This determines the root path from which data will be fetched.
   */
  rootPath: string;

  renderListingPage: (pages: T[]) => JSX.Element;
  renderPage: (page: T) => JSX.Element;
}) {
  function DocsListing() {
    const { data, error } = useFetcher<T[]>(`${config.rootPath}/index.json`);

    if (data) {
      return config.renderListingPage(data);
    }

    if (error) {
      return <ErrorMessage message="Could not load this page" />;
    }

    return <LoadingIndicator />;
  }

  function DocsListingLayout() {
    return (
      <MobileNavLayout title={config.title}>
        <DocsListing />
      </MobileNavLayout>
    );
  }

  function Doc(props: { pageId: string }) {
    const { data, error } = useFetcher<T>(
      `${config.rootPath}/${props.pageId}.json`,
    );

    if (data) {
      return config.renderPage(data);
    }

    if (error) {
      return <ErrorMessage message="Could not load this page" />;
    }

    return <LoadingIndicator />;
  }

  function DocLayout(props: { params: { pageId: string } }) {
    return (
      <MobileNavLayout title={config.title} backHref="/">
        <Doc pageId={props.params.pageId} />
      </MobileNavLayout>
    );
  }

  return function DocsPagesSwitch() {
    return (
      <Switch>
        <Route path="/" component={DocsListingLayout} />
        <Route path="/:pageId" component={DocLayout} />
      </Switch>
    );
  };
}
