import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./ui/app";

// Lazy load sentry to make sure it is not part of the initial bundle. This means
// that there are potentially bugs that Sentry won't catche, but on the other hand
// it saves about 100kb from our bundle.
import("./sentry");

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = document.getElementById("root")!;

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
