import { useDialog } from "@/ui/hooks/use-dialog";
import { ReactNode } from "react";
import { Spine } from "../spine";
import { NavControlsContext } from "../spine/context";
import * as css from "./style.css";

export function MainLayout(props: { children: ReactNode }) {
  const dialog = useDialog();

  return (
    <NavControlsContext.Provider value={dialog}>
      <div className={css.root}>
        <Spine />

        <div className={css.content}>{props.children}</div>
      </div>
    </NavControlsContext.Provider>
  );
}
