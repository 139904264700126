import { replaceKeywordValue } from "@/domain/keyword-types";
import { Trait } from "@/domain/traits";
import { Body } from "../body";
import { FauxDialogLayout } from "../faux-dialog-layout";
import { useRuleset } from "../game-content-provider";
import * as css from "./style.css";

export function QuickStartKeywords() {
  const ruleset = useRuleset();
  const keywords = ruleset.keywordsDefs.filter((k) => k.isDemoKeyword());

  return (
    <FauxDialogLayout heading="Keywords" theme="light">
      <p className={css.info}>
        Keywords with points can be purchased (or taken as a discount) during
        army building.
      </p>

      <div className={css.grid}>
        {keywords
          .flatMap((k) => {
            const variant =
              k.hasTrait(Trait.STRENGTH) ? "strength" : "weakness";

            const classname = css.keyword({ variant });
            const bannerSrc = `/quick-start/${variant}.png`;

            if (k.purchaseOptions.length) {
              return k.purchaseOptions.map((p) => {
                const name =
                  p.value ? replaceKeywordValue(k.name, `+${p.value}`) : k.name;

                const desc =
                  p.value ?
                    replaceKeywordValue(
                      k.descriptionWithTraits,
                      `[+${p.value}]`,
                    )
                  : k.descriptionWithTraits;

                return {
                  key: `${k.id}${p.cost}`,
                  classname,
                  name,
                  cost: `${p.cost > 0 ? "+" : ""}${p.cost}pts`,
                  desc,
                  defName: k.name,
                  bannerSrc,
                };
              });
            }

            return {
              key: k.id,
              classname,
              name: k.name,
              cost: "",
              desc: k.descriptionWithTraits,
              defName: k.name,
              bannerSrc,
            };
          })
          .map((k, index) => {
            return (
              <div
                key={k.key}
                className={k.classname}
                style={{ animationDelay: `${index * 150}ms` }}
              >
                <img
                  className={css.keywordTraitBanner}
                  src={k.bannerSrc}
                  alt=""
                />
                <h2 className={css.keywordHeading}>
                  {k.name}
                  {k.cost && ` (${k.cost})`}
                </h2>
                <Body
                  html={k.desc}
                  fontSize="0.875rem"
                  omitReferenceFor={k.name}
                />
              </div>
            );
          })}
      </div>
    </FauxDialogLayout>
  );
}
