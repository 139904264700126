import { Army } from "@/domain/army";
import { migrateArmy } from "@/domain/migrations";
import { toVersionName } from "@/lib/semver";
import { isNonEmpty } from "@/lib/typeguards";
import { ArmyData, SettingsData } from "@/schema/latest";
import { useDatabase } from "@/ui/database-provider";
import { useQuery } from "@/ui/database-provider/hooks";
import { ReactNode, useCallback } from "react";
import { Link } from "wouter";
import { Button } from "../button";
import { ConfirmDialog } from "../confirm-dialog";
import { useDialog } from "../hooks/use-dialog";
import {
  button,
  interactiveTextWhite,
  inverseTheme,
  primaryTheme,
} from "../utils.css";
import * as css from "./style.css";

function UpdateAllButton(props: {
  newVersion: string;
  armiesData?: ArmyData[];
  userSettingsData?: SettingsData;
  dialogTitle: string;
  dialogDescription: string;
  label: string;
}) {
  const database = useDatabase();
  const { store, isOpen, setOpen } = useDialog();

  return (
    <>
      {isOpen && (
        <ConfirmDialog
          store={store}
          heading={props.dialogTitle}
          message={props.dialogDescription}
          submit={props.label}
          submitTheme={primaryTheme}
          onSubmit={async () => {
            if (props.userSettingsData) {
              await database.migrateUserSettings(
                props.newVersion,
                props.userSettingsData,
              );
            }

            if (props.armiesData && isNonEmpty(props.armiesData)) {
              await Promise.all(
                props.armiesData.map(async (armyData) => {
                  return database.updateArmy(migrateArmy(armyData));
                }),
              );
            }
          }}
        />
      )}

      <Button className={button} style={inverseTheme} onClick={setOpen}>
        {props.label}
      </Button>
    </>
  );
}

export function RulesetStatusCard(props: {
  title: string;
  description: string;
  info?: ReactNode;
  actions?: ReactNode;
}) {
  return (
    <div className={css.upgradeAllCard}>
      <h2>{props.title}</h2>

      <p>{props.description}</p>

      {props.info}

      {props.actions && (
        <div style={{ marginTop: "1.5rem" }}>{props.actions}</div>
      )}
    </div>
  );
}

function RulesetUpgradeCard(props: {
  title: string;
  description: string;
  targetVersion: string;
  armiesData?: ArmyData[];
  userSettingsData?: SettingsData;
  label: string;
}) {
  return (
    <RulesetStatusCard
      title={props.title}
      description={props.description}
      info={
        props.armiesData &&
        props.armiesData.length > 0 && (
          <>
            <p>The following armies will be upgraded:</p>

            <ul>
              {props.armiesData.map((armyData) => {
                return (
                  <li key={armyData.id}>
                    <Link
                      href={`/army/a/${armyData.id}`}
                      className={interactiveTextWhite}
                    >
                      {armyData.name || Army.FALLBACK_NAME}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </>
        )
      }
      actions={
        <UpdateAllButton
          newVersion={props.targetVersion}
          armiesData={props.armiesData}
          userSettingsData={props.userSettingsData}
          dialogTitle={props.title}
          dialogDescription={props.description}
          label={props.label}
        />
      }
    />
  );
}

export function UpgradeRulesetCard(props: { targetVersion: string }) {
  const versionName = toVersionName(props.targetVersion);
  const outdatedItemsOp = useQuery(
    useCallback(
      (db) => db.getOutdatedItems(props.targetVersion),
      [props.targetVersion],
    ),
  );

  if (!outdatedItemsOp.isSuccess) {
    return null;
  }

  const { userSettingsData, armiesData } = outdatedItemsOp.value;

  // If everthing is up to date
  if (!userSettingsData && armiesData.length === 0) {
    return (
      <RulesetStatusCard
        title="Everything up to date!"
        description="Your default ruleset and all armies are using the latest version of Hobgoblin. Go fight some battles!"
      />
    );
  }

  // If there are no user settings to switch to prompt the user to upgrade armies
  if (!userSettingsData) {
    return (
      <RulesetUpgradeCard
        title={`Upgrade armies to ${versionName}?`}
        description={`This action will upgrade all your armies to ${versionName}. Points and keywords might change.`}
        armiesData={armiesData}
        targetVersion={props.targetVersion}
        label="Upgrade all"
      />
    );
  }

  // If there are no armies to upgrade, just prompt the user to switch default version.
  if (armiesData.length === 0) {
    return (
      <RulesetUpgradeCard
        title={`Switch to ${versionName}?`}
        description={`This action will switch your default ruleset to ${versionName}.`}
        targetVersion={props.targetVersion}
        userSettingsData={userSettingsData}
        label="Switch ruleset"
      />
    );
  }

  return (
    <RulesetUpgradeCard
      title={`Switch & upgrade to ${versionName}?`}
      description={`This action will switch your default ruleset to ${versionName} and upgrade all armies to match.`}
      targetVersion={props.targetVersion}
      armiesData={armiesData}
      userSettingsData={userSettingsData}
      label="Switch & upgrade"
    />
  );
}
