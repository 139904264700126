import { Exclusions } from "@/battle/exclusions";
import { KnownErrorMessage } from "@/ui/db-error";
import { LoadingIndicator } from "@/ui/loading-indicator";
import { ViewContainer } from "@/ui/max-width-container";
import { useEffect, useRef } from "react";
import { SectionId } from "../../lib/user-settings";
import { MobileNavLayout } from "../mobile-nav-layout";
import { useUserSettingsOp } from "../user-settings/context";
import * as css from "./style.css";

function SettingsSection(props: {
  sectionId: SectionId;
  heading: string;
  legend: string;
  entries: Array<{ id: string; name: string }>;
  excluded: string[];
  minOptions: number;
}) {
  const ref = useRef<HTMLElement>(null);
  const { sectionId } = props;

  useEffect(() => {
    if (window.location.hash === `#${sectionId}`) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [sectionId]);

  return (
    <section id={props.sectionId} className={css.section} ref={ref}>
      <h2 className={css.heading}>{props.heading}</h2>

      <Exclusions
        sectionId={props.sectionId}
        legend={props.legend}
        excluded={props.excluded}
        entries={props.entries}
        minOptions={props.minOptions}
      />
    </section>
  );
}

export function UserSettingsPage() {
  const userSettingsOp = useUserSettingsOp();

  if (userSettingsOp.isPending) {
    return <LoadingIndicator />;
  }

  if (userSettingsOp.isFailure) {
    return <KnownErrorMessage error={userSettingsOp.failure} />;
  }

  const userSettings = userSettingsOp.value;

  return (
    <MobileNavLayout title="Settings">
      <ViewContainer maxWidth={false} padding="small">
        <SettingsSection
          heading="Scenarios"
          sectionId="scenario"
          legend="Check scenarios you want to exclude from random selection."
          excluded={userSettings.excludedScenariosIds}
          entries={userSettings.ruleset.scenarios}
          minOptions={2}
        />

        <SettingsSection
          heading="Battlefield"
          sectionId="battlefield"
          legend="Check battlefields you want to exclude from random selection."
          excluded={userSettings.excludedBattlefieldsIds}
          entries={userSettings.ruleset.battlefields}
          minOptions={4}
        />

        <SettingsSection
          heading="Deployment"
          sectionId="deployment"
          legend="Check deployments you want to exclude from random selection."
          excluded={userSettings.excludedDeploymentsIds}
          entries={userSettings.ruleset.deployments}
          minOptions={2}
        />

        <SettingsSection
          heading="Twist"
          sectionId="twist"
          legend="Check twists you want to exclude from random selection."
          excluded={userSettings.excludedTwistsIds}
          entries={userSettings.ruleset.twists}
          minOptions={2}
        />
      </ViewContainer>
    </MobileNavLayout>
  );
}
