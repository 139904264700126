import { InfoPage } from "@/ui/info-page";
import { CoverImage, ListingItem, createDocsPages } from "../create-docs-pages";
import { ViewContainer } from "../max-width-container";
import { toDate } from "../../lib/date-format";

export type HelpPage = {
  id: string;
  href: string;
  data: {
    name: string;
    created_at?: string;
    updated_at?: string;
    author?: string;
  };
  content: string;
};

function HelpPage(props: {
  coverImageSrc?: string;
  name: string;
  author?: string;
  publishedDate?: Date | null;
  updatedDate?: Date | null;
  content: string;
  upgradeToVersion?: string;
}) {
  const publishedDate =
    props.publishedDate &&
    props.publishedDate.toLocaleDateString("en-GB", {
      dateStyle: "long",
    });

  const updatedDate =
    props.updatedDate &&
    `Last updated: ${props.updatedDate.toLocaleDateString("en-GB", {
      dateStyle: "long",
    })}`;

  return (
    <InfoPage>
      {props.coverImageSrc && <CoverImage src={props.coverImageSrc} />}

      <h1>{props.name}</h1>

      <p>
        <em>
          {[props.author, publishedDate, updatedDate]
            .filter((i) => !!i)
            .join(", ")}
        </em>
      </p>

      <div dangerouslySetInnerHTML={{ __html: props.content }} />
    </InfoPage>
  );
}

export const HelpPages = createDocsPages<HelpPage>({
  title: "Help",
  rootPath: "/docs/help",
  renderListingPage(pages) {
    return (
      <ViewContainer>
        {pages.map((page, index) => {
          return (
            <ListingItem
              key={page.id}
              href={page.href}
              name={page.data.name}
              createdAt={toDate(page.data.created_at)}
              showCover={index === 0}
            />
          );
        })}
      </ViewContainer>
    );
  },
  renderPage(page) {
    return (
      <HelpPage
        name={page.data.name}
        author={page.data.author}
        publishedDate={toDate(page.data.created_at)}
        updatedDate={
          page.data.updated_at ? new Date(page.data.updated_at) : null
        }
        content={page.content}
      />
    );
  },
});
