import { Failure } from "@/lib/async-op";
import { HobgoblinDatabase } from "@/lib/db";
import { FortuneData } from "@/schema/latest";
import { useQuery } from "@/ui/database-provider/hooks";
import { QueryOp } from "@/ui/database-provider/types";
import { useRuleset } from "@/ui/game-content-provider";
import type { ReactNode } from "react";
import { createContext, useCallback, useContext } from "react";

export type UsedFortune = {
  name: string;
  description: string;
  cardId: string;
  persisted?: boolean;
};

export type AddCard = (fortune: UsedFortune) => void;

export type RemoveCard = (cardId: string) => void;

const FortunesContext = createContext<QueryOp<FortuneData>>(
  new Failure({
    message: "FortunesProvider not present as parent in component hierarchy.",
    type: "ERROR",
  }),
);

export const useFortuneCards = () => useContext(FortunesContext);

export function FortunesProvider(props: { children: ReactNode }) {
  const ruleset = useRuleset();
  const getLatestFortune = useCallback(
    (db: HobgoblinDatabase) => db.getFortuneHand(ruleset.version),
    [ruleset.version],
  );
  const result = useQuery(getLatestFortune, "fortune");

  return (
    <FortunesContext.Provider value={result}>
      {props.children}
    </FortunesContext.Provider>
  );
}
