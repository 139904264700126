import { useDatabase } from "@/ui/database-provider";
import { Button } from "@/ui/button";
import { useIsInstalled } from "@/ui/hooks/use-is-installed";
import { useBrowserName } from "@/ui/hooks/use-is-safari";
import { usePromptState } from "@/ui/hooks/use-prompt-state";
import { Image } from "@/ui/image";
import { InfoDialog } from "@/ui/info-dialog";
import { Link } from "@/ui/link";
import {
  button,
  interactiveText,
  paragraph,
  primaryTheme,
  transparentTheme,
} from "@/ui/utils.css";
import { DialogButton } from "../dialog-button";
import { CreateArmyButton } from "../armies-page/create-army-button";
import * as css from "./style.css";

function SafariPrompt(props: { onDismiss: () => void; browserName: string }) {
  const isMobile = props.browserName.includes("Mobile");

  const safely_backed_up = (
    <Link className={interactiveText} href="/help/backup-and-sync">
      safely backed up
    </Link>
  );

  const delete_your_local_data = <strong>delete your local data</strong>;

  const install_the_app = <strong>install the app</strong>;

  const creating_an_account = <strong>creating an account</strong>;

  return (
    <div className={css.safariPromptContainer}>
      <div className={css.safariPrompt}>
        <div className={css.safariPromptHeader}>
          <img
            src="/logos/safari.svg"
            alt=""
            width="64"
            height="64"
            className={css.safariLogo}
          />

          <div className={css.emptyStateHeading}>Heads up Safari users!</div>
        </div>
        <p className={paragraph}>
          Unlike other browsers, Safari will {delete_your_local_data} after 7
          days of inactivity, unless you have installed the app.
        </p>
        <p className={paragraph}>
          To prevent accidental data loss, you can either {install_the_app}, or
          make sure all your data is {safely_backed_up} by {creating_an_account}{" "}
          at Indie Tabletop Club.
        </p>

        <div className={css.safariPromptActions}>
          <DialogButton
            className={button}
            style={primaryTheme}
            renderDialog={(state) => {
              const shareIcon = (
                <img
                  src="/docs/help/share-icon.svg"
                  alt=""
                  className={css.icon}
                />
              );

              const addIcon = (
                <img
                  src="/docs/help/add-to-home-screen.svg"
                  alt=""
                  className={css.icon}
                />
              );

              const addToDockIcon = (
                <img
                  src="/docs/help/add-to-dock.svg"
                  alt=""
                  className={css.icon}
                />
              );

              return (
                <InfoDialog {...state}>
                  <img
                    src="/icon-512.png"
                    alt=""
                    width="512"
                    height="512"
                    className={css.appIcon}
                  />

                  <h1 className={css.heading}>Install App</h1>

                  <p className={css.intro}>
                    To install Hobgoblin, follow these 3 steps:
                  </p>

                  <ol className={css.steps}>
                    <li className={css.step}>
                      Open the <strong>Share</strong> menu {shareIcon}
                    </li>
                    <li className={css.step}>
                      {isMobile ?
                        <>
                          Tap <strong>Add to Home Screen</strong> {addIcon}
                        </>
                      : <>
                          Click <strong>Add to Dock</strong> {addToDockIcon}
                        </>
                      }
                    </li>
                    <li className={css.step}>
                      {isMobile ?
                        <>
                          Tap <strong>Add</strong> in the top right corner
                        </>
                      : <>
                          Click <strong>Add</strong>
                        </>
                      }
                    </li>
                  </ol>

                  <div>
                    <Button
                      className={interactiveText}
                      onClick={() => state.store.hide()}
                    >
                      Dismiss
                    </Button>
                  </div>
                </InfoDialog>
              );
            }}
          >
            Install App
          </DialogButton>

          <a className={button} style={primaryTheme} href="/join">
            Create Account
          </a>
        </div>

        <div className={css.safariDismissArea}>
          <Button className={interactiveText} onClick={props.onDismiss}>
            I don’t mind if my data gets deleted
          </Button>
        </div>
      </div>
    </div>
  );
}

export function ArmiesPageEmptyState(props: { rulesetVersion: string }) {
  const database = useDatabase();
  const browserNameOp = useBrowserName();
  const isInstalled = useIsInstalled();
  const [dismissedAt, setDismissedAt] = usePromptState(
    "safari_install_prompt_dismissed_at",
  );
  const wasPreviouslyDismissed = !!dismissedAt;

  // If the safari prompt was previously dismissed, or the app is already installed,
  // we can skip this branch entirely.
  if (!wasPreviouslyDismissed && !isInstalled) {
    // As long as borwser name check is pending we want to avoid showing anything to
    // prevent annoying flicker.
    if (browserNameOp.isPending) {
      return null;
    }

    // If browser name includes Safari we want to show this message. Note that the
    // reason we use include is that the result can contain Safari or Mobile Safari.
    const browserName = browserNameOp.valueOrNull();
    if (browserName?.includes("Safari")) {
      return (
        <SafariPrompt
          onDismiss={() => setDismissedAt(Date.now())}
          browserName={browserName}
        />
      );
    }
  }

  // If none of the above returns, we render the default empty state.
  return (
    <div className={css.emptyState}>
      <div className={css.emptyStateMessage}>
        <Image
          src="/images/scroll.webp"
          width={600}
          height={400}
          className={css.emptyStateImage}
        />
        <div className={css.emptyStateHeading}>No armies yet</div>

        <div className={css.emptyStateHint}>
          Create sample armies or start from scratch.
        </div>
      </div>
      <div className={css.emptyStateButtons}>
        <Button
          className={button}
          style={primaryTheme}
          onClick={async () => {
            await database.createDummyArmies();
          }}
        >
          Create Sample Armies
        </Button>

        <CreateArmyButton {...props} theme={transparentTheme} />
      </div>
    </div>
  );
}
