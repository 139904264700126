import { ReactNode, useEffect } from "react";
import { Link, useLocation } from "wouter";
import * as css from "./style.css";

export function FauxDialogLayout(props: {
  heading: string;
  children: ReactNode;
  theme: NonNullable<css.RootVariants["theme"]>;
}) {
  const { theme } = props;
  const [_, navigate] = useLocation();

  useEffect(() => {
    const handleKeypress = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        navigate("/");
      }
    };

    window.addEventListener("keydown", handleKeypress);

    return () => {
      window.removeEventListener("keydown", handleKeypress);
    };
  }, [navigate]);

  return (
    <div className={css.root({ theme })}>
      <div className={css.container}>
        <div className={css.header}>
          <div />

          <div>
            <h1 className={css.heading}>{props.heading}</h1>
          </div>

          <Link href="/" className={css.dismissButton({ theme })}>
            <div className={css.dismissHoverIndicator({ theme })} />
            <svg
              width="40px"
              height="40px"
              viewBox="0 0 40 40"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Dismiss</title>
              <g id="cross" stroke="none" strokeWidth="1" fillRule="evenodd">
                <path
                  d="M19.7950413,9 C20.2850813,10.4793814 21.2145658,14.2578982 21.4015521,18.5119422 C25.6904889,18.657261 29.5105293,19.4290466 31,19.92245 C29.9161318,20.5586335 25.2182432,21.194817 21.406857,21.3306953 C21.2374836,25.6488846 20.2911051,29.5024068 19.7950413,31 C19.4298163,29.2033922 18.7392999,25.0205897 18.5929351,21.3291715 C14.8888553,21.192497 10.6228379,20.5574735 9,19.92245 C10.4894707,19.4290466 14.3095111,18.657261 18.5990431,18.5117565 C18.7588884,14.8608027 19.4350141,10.7710135 19.7950413,9 Z"
                  fillRule="nonzero"
                  transform="translate(20, 20) rotate(-45) translate(-20, -20)"
                />
              </g>
            </svg>
          </Link>
        </div>

        {props.children}
      </div>
    </div>
  );
}
