import { ErrorMessage } from "../error-message";

export function KnownErrorMessage(props: {
  error: { type: string; message?: string };
}) {
  const { error } = props;

  switch (error.type) {
    case "DB_VERSION_HIGHER_THAN_REQUESTED": {
      return (
        <ErrorMessage
          message="Could not open database. Try refreshing the page."
          code={error.type}
        />
      );
    }

    case "CLOSED_FOR_UPGRADE": {
      return (
        <ErrorMessage
          message="Database was closed due to version upgrade. Please refresh the page."
          code={error.type}
        />
      );
    }

    case "UPGRADE_BLOCKED": {
      return (
        <ErrorMessage
          message="Database could not be opened due to an upgrade being blocked by other browser tabs. Please close other browser tabs with this application and then refresh the page."
          code={error.type}
        />
      );
    }

    case "QUERY_ERROR": {
      return (
        <ErrorMessage
          message="Database query failed. Sorry about this. Perhaps try refreshing the page?"
          details={error.message}
          code={error.type}
        />
      );
    }

    case "RULESET_NOT_FOUND": {
      return (
        <ErrorMessage
          message={error.message ?? "Ruleset version not found."}
          code={error.type}
        />
      );
    }

    case "FETCH_FAILED": {
      return (
        <ErrorMessage
          message="Network request failed. Make sure you're connected to the internet and try again by refreshing the app."
          details={error.message}
          code={error.type}
        />
      );
    }

    case "ARMY_NOT_FOUND": {
      return (
        <ErrorMessage
          message="This army was not found. The link might be broken, or perhaps you are not logged into the right account?"
          details={error.message}
          code={error.type}
        />
      );
    }

    case "ARMY_UNIT_NOT_FOUND": {
      return (
        <ErrorMessage
          message="Unit not found because its containing army was not found. The link might be broken, or perhaps you are not logged into the right account?"
          details={error.message}
          code={error.type}
        />
      );
    }

    case "ERROR":
    default: {
      return (
        <ErrorMessage
          message="Ooops! This wasn't supposed to happen. The error was reported. If the issue persists, please get in touch with 'artmllr' via Discord."
          details={error.message}
          code={error.type}
        />
      );
    }
  }
}
