import { DialogStore } from "@ariakit/react";
import { createContext, useContext } from "react";

export const NavControlsContext = createContext<null | {
  setOpen: () => void;
  setClosed: () => void;
  toggleOpen: () => void;
  store: DialogStore;
}>(null);

export function useNavControls() {
  const context = useContext(NavControlsContext);
  if (!context) {
    throw new Error(`NavControls context not set.`);
  }
  return context;
}
