import { Army } from "@/domain/army";
import { toLocaleString } from "@/lib/date-format";
import { Session } from "@ory/client";
import { ErrorBoundary } from "react-error-boundary";
import { Link } from "../link";
import { ViewContainer } from "../max-width-container";
import { MobileNavLayout } from "../mobile-nav-layout";
import { useSession } from "../session-provider/context";
import { getLoginUrl } from "../session-provider/ory";
import {
  LogItem,
  syncStorage,
  useObserveSyncStorage,
} from "../sync-handler/sync-storage";
import { interactiveText, primaryTheme } from "../utils.css";
import * as css from "./style.css";

function LoggedOutSection() {
  return (
    <section className={css.section}>
      <h2 className={css.heading}>Account</h2>

      <p className={css.paragraph}>
        Join Indie Tabletop Club or log into your account to enable army backup
        and sync.{" "}
        <Link href="/help/backup-and-sync" className={interactiveText}>
          Learn more
        </Link>
        .
      </p>

      <div className={css.actions}>
        <a className={css.actionButton} style={primaryTheme} href="/join">
          Join
        </a>

        <a className={css.actionButton} href={getLoginUrl()}>
          Log in
        </a>
      </div>
    </section>
  );
}

function AccountSection(props: { session: Session; logOut: () => void }) {
  const { session, logOut } = props;

  return (
    <section className={css.section}>
      <h2 className={css.heading}>Account</h2>
      <p className={css.paragraph}>
        Logged in as: {session.identity?.traits?.email}
        <br />
        Authenticated:{" "}
        {toLocaleString(session.authenticated_at, {
          dateStyle: "long",
          timeStyle: "medium",
        })}
        <br />
        Expires:{" "}
        {toLocaleString(session.expires_at, {
          dateStyle: "long",
          timeStyle: "medium",
        })}
      </p>
      <p className={css.paragraph}>
        <button className={interactiveText} onClick={logOut}>
          Log out
        </button>
      </p>
    </section>
  );
}

function typeToLabel(type: LogItem["type"]) {
  const record: Record<typeof type, string> = {
    PULL_SUCCESS: `✅ Pulled data from the cloud.`,
    PUSH_SUCCESS: `✅ Pushed data to the cloud.`,
    PUSH_NO_CHANGES: `🆗 Push not needed. Local data hasn't changed since last sync.`,
    PULL_NO_CHANGES: `🆗 Pull complete. Local data already up to date.`,
    SYNC_ERROR: `🛑 An error occurred during sync.`,
  };

  return record[type] ?? "🌀 Unrecognised log item type.";
}

function LogItemRow(props: LogItem) {
  const date = toLocaleString(props.ts, {
    dateStyle: "short",
    timeStyle: "medium",
  });

  return (
    <div className={css.logItem}>
      <div>
        <div>
          {typeToLabel(props.type)}{" "}
          <span className={css.timestamp}>{date}</span>
        </div>
      </div>

      {"records" in props && (
        <div className={css.logItemRecords}>
          {props.records.map((record) => {
            if (record.deleted) {
              return (
                <div key={record.id}>
                  Synced deletion of {record.name || Army.FALLBACK_NAME} (ID{" "}
                  {record.id})
                </div>
              );
            }

            return (
              <div key={record.id}>
                Synced{" "}
                <Link href={`/army/a/${record.id}`} className={interactiveText}>
                  {record.name}
                </Link>{" "}
                (ID: {record.id})
              </div>
            );
          })}
        </div>
      )}

      {"error" in props && (
        <div className={css.logItemRecords}>{props.error}</div>
      )}
    </div>
  );
}

export function SyncLogError() {
  return (
    <section className={css.section}>
      <h2 className={css.heading}>Sync log failed to load due to error</h2>

      <button
        style={{ border: "1px solid red", padding: "0.5rem 1rem" }}
        onClick={() => {
          syncStorage.syncLog = [];
          location.reload();
        }}
      >
        Clear log and refresh
      </button>
    </section>
  );
}

function SyncLogSection() {
  const log = useObserveSyncStorage("syncLog");
  const isSyncing = useObserveSyncStorage("isSyncing");
  const lastSuccessfulSyncTs = useObserveSyncStorage("lastSuccessfulSyncTs");

  const lastSyncText =
    lastSuccessfulSyncTs ?
      `Last successful sync: ${toLocaleString(lastSuccessfulSyncTs, {
        dateStyle: "long",
        timeStyle: "medium",
      })}.`
    : "Not yet synced.";

  return (
    <section className={css.section}>
      <h2 className={css.heading}>Sync log</h2>

      <p className={css.paragraph}>{lastSyncText}</p>

      <p className={css.paragraph}>
        <button
          className={interactiveText}
          onClick={() => {
            syncStorage.syncLog = [];
          }}
        >
          Clear log
        </button>
      </p>

      <div className={css.logItems}>
        {isSyncing && <div className={css.logItem}>Syncing...</div>}

        {log.length === 0 ?
          "No items"
        : log.map((item) => {
            const key = `${item.ts}${item.type}`;
            return <LogItemRow key={key} {...item} />;
          })
        }
      </div>
    </section>
  );
}

export function AccountPageMain() {
  const { isRetrievingSession, isLoggedIn, session, logOut } = useSession();

  if (isRetrievingSession) {
    return null;
  }

  if (!isLoggedIn || !session) {
    return <LoggedOutSection />;
  }

  return (
    <>
      <AccountSection session={session} logOut={logOut} />

      <ErrorBoundary fallback={<SyncLogError />}>
        <SyncLogSection />
      </ErrorBoundary>
    </>
  );
}

export function AccountPage() {
  return (
    <MobileNavLayout title="Account">
      <ViewContainer maxWidth={false} padding="small">
        <AccountPageMain />
      </ViewContainer>
    </MobileNavLayout>
  );
}
